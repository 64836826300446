<template>
  <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
    <!-- Form -->
    <b-form @submit.prevent="handleSubmit(() => onSubmit($event.submitter.name))" @reset.prevent="resetForm">
      <b-row>
        <b-col class="col-lg-4 col-md-12 col-sm-12 col-12">
          <vehicule-form-identity class="mb-2" :vehicule-data="vehiculeData" :selected-client-id.sync="selectedClientId"
            :read-only="isFrontOfficeUser" :is-front-office-user="isFrontOfficeUser" :is-update="isUpdate"
            @updateVehiculeData="updateVehiculeData" />
        </b-col>
        <b-col>
          <b-row>
            <b-col class="col-lg-12 col-md-12 col-sm-12 col-12">
              <vehicule-form-in :vehicule-data="vehiculeData" :selected-client-id="selectedClientId"
                :read-only="isFrontOfficeUser" :is-front-office-user="isFrontOfficeUser" />
            </b-col>
            <b-col class="col-lg-12 col-md-12 col-sm-12 col-12 mt-2">
              <vehicule-form-reserve :vehicule-data="vehiculeData" :read-only="isFrontOfficeUser" />
            </b-col>
          </b-row>
        </b-col>

        <b-col v-if="isUpdate" class="col-lg-4 col-md-12 col-sm-12 col-12 mt-md-2 mt-lg-0 mt-sm-2 mt-2">
          <vehicule-form-out :vehicule-data="vehiculeData" :is-update="isUpdate" :selected-client-id="selectedClientId"
            :is-front-office-user="isFrontOfficeUser"
            :read-only="!canUpdateVehicule || (isFrontOfficeUser && vehiculeData.vehiculeStep === 'sortie')" />
        </b-col>

      </b-row>

      <!-- Form Actions -->
      <div class="d-flex mt-2 align-items-center">
        <b-button v-if="canUpdateVehicule && !isFrontOfficeUser" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary" class="mr-2" type="submit" :name="SUBMIT_AND_PRINT_BTN_NAME">
          Sauvegarder et imprimer étiquette
        </b-button>
        <b-button v-if="canUpdateVehicule && !isFrontOfficeUser" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary" class="mr-2" type="submit"
          :name="isUpdate ? SUBMIT_UPDATE_BTN_NAME_AS_ADMIN : SUBMIT_ADD_BTN_NAME_AS_ADMIN">
          Sauvegarder
        </b-button>
        <b-button v-if="isFrontOfficeUser && canUpdateVehicule && vehiculeData.vehiculeStep !== 'sortie'"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit"
          :name="SUBMIT_BTN_NAME_AS_FRONTOFFICE">
          {{ buttonLabelForFrontOfficeUser }}
        </b-button>
        <b-form-checkbox v-if="!isUpdate" v-model="addNewVehicleAfterSave">
          Ajouter un nouveau véhicule après la sauvegarde
        </b-form-checkbox>
      </div>

      <div v-if="!canUpdateVehicule" class="d-flex mt-2">
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2"
          @click="() => $emit('cancelVehicule', vehiculeData.id)">
          Annuler la demande
        </b-button>
      </div>
    </b-form>
    <!-- Interventions -->
    <div class="interventions">
      <ul v-if="vehiculeData.interventions" class="interventions-list">
        <li v-for="(intervention, index) in vehiculeData.interventions" :key="index">
          {{ actionInterventionLabel(intervention.type) }} le {{ formatDateIntervention(intervention.dateCreated) }} à {{
            formatHeureIntervention(intervention.dateCreated) }} par {{ intervention.utilisateur }}
        </li>
      </ul>
    </div>

  </validation-observer>
</template>

<script>
import {
  BForm, BButton, BCol, BRow, BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import router from '@/router'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'
import VehiculeFormIdentity from './VehiculeFormIdentity.vue'
import InterventionType from './InterventionType'
import VehiculeFormIn from './VehiculeFormIn.vue'
import VehiculeFormReserve from './VehiculeFormReserve.vue'
import VehiculeFormOut from './VehiculeFormOut.vue'
import VehiculeStep from './VehiculeStep'
import { StorageManager, FILTER_CLIENT_KEY } from '../../utils/StorageManager'

export default {
  components: {
    BForm,
    BButton,
    BCol,
    BRow,
    BFormCheckbox,

    VehiculeFormIdentity,
    VehiculeFormIn,
    VehiculeFormReserve,
    VehiculeFormOut,

    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    isUpdate: {
      type: Boolean,
      default: false,
    },
    vehiculeFormData: {
      type: Object,
      default: () => ({
        // Indentity
        clientId: null,
        couleur: '',
        numeroSerie: '',
        vehiculeType: 'neuf',
        modeleId: null,
        immatriculation: '',
        numeroOrdre: '',
        commentaire: '',
        ots: false,
        gravage: false,
        preparationStep: 'aucune',
        relavageEffectue: false,
        photoPrise: false,
        // IN
        receptionnaireId: null,
        siteProvenanceId: null,
        selectedSiteProvenanceCode: '',
        dateARA: null,
        emplacement: '',

        // Reserve
        reserve: '',
        dateEnvoiAtelier: null,
        dateRetourAtelier: null,

        // OUT
        dateFacturation: null,
        dateLivraison: null,
        datePreparation: null,
        siteDestinationId: null,
        clientFinal: '',
        montantCarburant: '',
        codeAutoradio: '',
        cedeA: '',
        accessoire: false,
        vendeur: '',
        clientType: null,
        commentaireSortie: '',

      }),
    },
  },
  data() {
    return {
      selectedClientId: null,
      isFrontOfficeUser: false,
    }
  },
  computed: {
    canUpdateVehicule() {
      return !this.isFrontOfficeUser || !this.isUpdate || this.vehiculeData.vehiculeStep !== VehiculeStep.EN_ATTENTE_DE_DESTOCKAGE
    },
    buttonLabelForFrontOfficeUser() {
      if (this.vehiculeData.vehiculeStep === VehiculeStep.STOCK) {
        return 'Demander la sortie du parc'
      }
      if (this.vehiculeData.vehiculeStep === VehiculeStep.EN_ATTENTE_DE_DESTOCKAGE) {
        return 'Annuler la demande de sortie du parc'
      }
      return 'Sauvegarder'
    },
  },
  mounted() {
    if (!this.isUpdate) {
      if (router.currentRoute.params.clientId !== null && router.currentRoute.params.clientId !== undefined) {
        this.selectedClientId = router.currentRoute.params.clientId
      } else if (StorageManager.getFilter(FILTER_CLIENT_KEY) != null) {
        this.selectedClientId = StorageManager.getFilter(FILTER_CLIENT_KEY)
      }
    }
    this.isFrontOfficeUser = !this.$ability.can('manage', 'Vehicule')
  },
  methods: {
    formatDateIntervention(dateIntervention) {
      return dateIntervention ? moment.utc(dateIntervention).local().format('DD/MM/YYYY') : ''
    },
    formatHeureIntervention(dateIntervention) {
      return dateIntervention ? moment.utc(dateIntervention).local().format('HH:mm:ss') : ''
    },
    actionInterventionLabel(typeIntervention) {
      switch (typeIntervention) {
        case InterventionType.CREATION:
          return 'Véhicule crée'
        case InterventionType.MODIFICATION:
          return 'Véhicule modifié'
        case InterventionType.DEMANDE_DESTOCKAGE:
          return 'Demande de destockage'
        case InterventionType.ANNULATION_DESTOCKAGE:
          return 'Annulation de la demande de destockage'
        default:
          return 'Véhicule modifié'
      }
    },
  },
  setup(props, { emit }) {
    const vehiculeData = ref({ ...props.vehiculeFormData })
    const addNewVehicleAfterSave = ref({ ...false })
    const resetVehiculeData = () => {
      vehiculeData.value = { ...props.vehiculeFormData }
    }
    const updateVehiculeData = newVehiculeDataValue => {
      vehiculeData.value = { ...newVehiculeDataValue }
    }

    const SUBMIT_UPDATE_BTN_NAME_AS_ADMIN = 'submit update'
    const SUBMIT_ADD_BTN_NAME_AS_ADMIN = 'submit add'
    const SUBMIT_BTN_NAME_AS_FRONTOFFICE = 'submit as front office'
    const SUBMIT_AND_PRINT_BTN_NAME = 'submitAndPrint'

    const onSubmit = submitButtonName => {
      switch (submitButtonName) {
        case SUBMIT_ADD_BTN_NAME_AS_ADMIN:
          emit('submitVehiculeForm', vehiculeData.value, addNewVehicleAfterSave.value)
          break
        case SUBMIT_UPDATE_BTN_NAME_AS_ADMIN:
          emit('submitVehiculeForm', 'vehicule/updateVehicule', vehiculeData.value)
          break
        case SUBMIT_BTN_NAME_AS_FRONTOFFICE:
          emit('submitVehiculeForm', 'vehicule/updateFrontOfficeVehicule', vehiculeData.value)
          break
        case SUBMIT_AND_PRINT_BTN_NAME:
          emit('submitVehiculeFormAndPrintEtiquette', 'vehicule/updateVehicule', vehiculeData.value, addNewVehicleAfterSave.value)
          break
        default:
      }
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetVehiculeData)

    return {
      vehiculeData,
      addNewVehicleAfterSave,
      updateVehiculeData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,

      SUBMIT_ADD_BTN_NAME_AS_ADMIN,
      SUBMIT_UPDATE_BTN_NAME_AS_ADMIN,
      SUBMIT_BTN_NAME_AS_FRONTOFFICE,
      SUBMIT_AND_PRINT_BTN_NAME,
    }
  },
}
</script>
<style lang="scss" scoped>
.interventions-list{
list-style-type: none;
padding-bottom: 20px;
}
</style>
