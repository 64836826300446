<template>
  <b-card
    no-body
    class="mb-0 p-2"
  >

    <h5>
      Entrée sur parc
    </h5>

    <b-row>
      <b-col>
    <!-- Réceptionnaire -->

    <validation-provider
      v-if="!isFrontOfficeUser"
      #default="validationContext"
      rules="required"
    >
      <b-form-group
        label="Réceptionnaire"
        label-for="receptionnaireId"
        :state="getValidationState(validationContext)"
      >
        <v-select
          v-model="vehiculeData.receptionnaireId"
          :options="receptionnairesOptions"
          :create-option="value => ({ id: value.id, nom: value.nom + '' + value.prenom})"
          input-id="receptionnaireId"
          label="nom"
          :reduce="receptionnaire => receptionnaire.id"
          :disabled="readOnly"
          class="select-cursor"
        >
          <template
            slot="option"
            slot-scope="option"
          >
            {{ option.prenom }} {{ option.nom }}
          </template>
          <template
            slot="selected-option"
            slot-scope="option"
          >
            {{ option.prenom }}  {{ option.nom }}
          </template>
          <div slot="no-options">
            Aucune option
          </div>
        </v-select>

        <b-form-invalid-feedback :state="getValidationState(validationContext)">
          Le champ Réceptionnaire est requis
        </b-form-invalid-feedback>
      </b-form-group>
    </validation-provider>

    <b-container
      v-if="isFrontOfficeUser"
      class="p-0"
    >
      <label>Réceptionnaire</label>

      <b-form-input
        v-if="isFrontOfficeUser"
        v-model="vehiculeData.receptionnaireNom"
        class="mb-1"
        trim
        :disabled="readOnly"
      />

    </b-container>

    <!-- Site Client -->

    <validation-provider
      v-if="!isFrontOfficeUser"
      #default="validationContext"
      name="Site client"
      rules="required"
    >
      <b-form-group
        label="Site client"
        label-for="siteProvenanceId"
        :state="getValidationState(validationContext)"
      >
        <v-select
          v-model="vehiculeData.siteProvenanceId"
          :value="selectedSiteProvenance"
          :options="siteProvenancesOptions"
          input-id="siteProvenanceId"
          label="nom"
          :reduce="site => site.id"
          :disabled="readOnly"
          class="select-cursor"
        ><div slot="no-options">
          Aucune option
        </div></v-select>

        <b-form-invalid-feedback :state="getValidationState(validationContext)">
          Le champ Site Client est requis
        </b-form-invalid-feedback>
      </b-form-group>
    </validation-provider>

    <b-container
      v-if="isFrontOfficeUser"
      class="p-0"
    >
      <label>Site Client</label>

      <b-form-input
        v-if="isFrontOfficeUser"
        v-model="vehiculeData.siteProvenanceNom"
        class="mb-1"
        trim
        :disabled="readOnly"
      />
    </b-container>

    <!-- Code site client-->
    <b-form-group
      label="Code site client"
      label-for="codeSiteClient"
    >
      <b-form-input
        id="codeSiteClient"
        :value="selectedSiteProvenanceCode"
        readonly
        trim
      />
    </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        cols="12"
        md="6">
    <!-- Date D'ARA -->
    <validation-provider
      #default="validationContext"
      name="Date D'ARA"
      rules="required"
    >
      <b-form-group
        label="Date D'ARA"
        label-for="dateARA"
      >
        <b-form-datepicker
          id="dateARA"
          v-model="vehiculeData.dateARA"
          start-weekday="1"
          class="w-100"
          reset-button
          today-button
          :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
          v-bind="labels || {}"
          placeholder="Saisir une date"
          :state="getValidationState(validationContext) ? null : getValidationState(validationContext)"
          :disabled="readOnly"
        />

        <b-form-invalid-feedback :state="getValidationState(validationContext)">
          Le champ Date d'ARA est requis
        </b-form-invalid-feedback>
      </b-form-group>
    </validation-provider>

  </b-col>
  <b-col
        cols="12"
        md="6">
    <!-- Emplacement -->
    <b-form-group
      label="Emplacement"
      label-for="emplacement"
    >
      <b-form-input
        id="emplacement"
        v-model="vehiculeData.emplacement"
        autofocus
        trim
        :disabled="readOnly"
      />
    </b-form-group>

  </b-col>

</b-row>
  </b-card>
</template>

<script>
import {
  BCard, BFormGroup, BFormInvalidFeedback, BFormDatepicker, BFormInput, BContainer, BCol, BRow,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import datePickerLabels from '@/utils/datePickerLabels'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import vSelect from 'vue-select'

export default {
  components: {
    BCard,
    BFormGroup,
    BFormInvalidFeedback,
    BFormDatepicker,
    BFormInput,
    BContainer,
    BCol,
    BRow,
    vSelect,

    // Form Validation
    ValidationProvider,
  },
  directives: {
    Ripple,
  },
  props: {
    readOnly: {
      type: Boolean,
      default: false,
    },
    vehiculeData: {
      type: Object,
      default: () => ({}),
    },
    selectedClientId: {
      type: [Number, null],
      default: null,
    },
    isFrontOfficeUser: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      required,
      labels: datePickerLabels,
      selectedSiteProvenance: null,
      receptionnairesOptions: [],
      siteProvenancesOptions: [],
    }
  },
  computed: {
    selectedSiteProvenanceCode() {
      const site = this.siteProvenancesOptions.find(element => element.id === this.vehiculeData.siteProvenanceId)
      return (site ? site.code : null)
    },
  },
  watch: {
    selectedClientId(newClientId) {
      if (!this.isFrontOfficeUser && newClientId !== null) {
        store.dispatch('site/fetchSites', newClientId)
          .then(response => {
            this.siteProvenancesOptions = response.data.filter(site => site.siteType === 'provenance')
            this.vehiculeData.siteProvenanceId = null
          })
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (!this.isFrontOfficeUser) {
        store.dispatch('receptionnaire/fetchReceptionnaires')
          .then(response => {
            this.receptionnairesOptions = response.data
          })
        if (this.vehiculeData.clientId) {
          store.dispatch('site/fetchSites', this.vehiculeData.clientId)
            .then(response => {
              this.siteProvenancesOptions = response.data.filter(site => site.siteType === 'provenance')
            })
        }
      }
    })
  },
  setup() {
    const { getValidationState } = formValidation()

    return { getValidationState }
  },
}
</script>
