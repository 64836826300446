<template>
  <b-card
    v-if="vehiculeData.vehiculeType == 'neuf'"
    no-body
    class="mb-0 p-2"
  >

    <h5>
      Réserve
    </h5>

    <b-row>
      <b-col>
    <b-form-checkbox
      v-model="checkboxValue"
      :disabled="readOnly"
    >
      Réserve sur le véhicule
    </b-form-checkbox>

    <!-- Réserve -->
    <validation-provider
      #default="validationContext"
      name="Réserve"
    >
      <b-form-group
        label="Réserve"
        label-for="reserve"
      >
        <b-form-textarea
          id="reserve"
          v-model="vehiculeData.reserve"
          autofocus
          rows="2"
          :disabled="readOnly || !checkboxValue"
        />

        <b-form-invalid-feedback>
          {{ validationContext.errors[0] }}
        </b-form-invalid-feedback>
      </b-form-group>
    </validation-provider>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        md="6">
    <!-- Date d'envoi atelier -->
    <validation-provider
      #default="validationContext"
      name="Date d'envoi atelier"
    >
      <b-form-group
        label="Date d'envoi atelier"
        label-for="dateEnvoiAtelier"
      >
        <b-form-datepicker
          id="dateEnvoiAtelier"
          v-model="vehiculeData.dateEnvoiAtelier"
          start-weekday="1"
          class="w-100"
          reset-button
          today-button
          :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
          v-bind="labels || {}"
          placeholder="Saisir une date"
          :disabled="readOnly || !checkboxValue"
          :max="vehiculeData.dateRetourAtelier"
        />

        <b-form-invalid-feedback :state="getValidationState(validationContext)">
          {{ validationContext.errors[0] }}
        </b-form-invalid-feedback>
      </b-form-group>
    </validation-provider>
  </b-col>
  <b-col
        cols="12"
        md="6">
    <!-- Date de retour atelier -->
    <validation-provider
      #default="validationContext"
      name="Date de retour atelier"
    >
      <b-form-group
        label="Date de retour atelier"
        label-for="dateRetourAtelier"
      >
        <b-form-datepicker
          id="dateRetourAtelier"
          v-model="vehiculeData.dateRetourAtelier"
          start-weekday="1"
          class="w-100"
          reset-button
          today-button
          :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
          v-bind="labels || {}"
          placeholder="Saisir une date"
          :disabled="readOnly || !checkboxValue"
          :min="vehiculeData.dateEnvoiAtelier"
        />

        <b-form-invalid-feedback :state="getValidationState(validationContext)">
          {{ validationContext.errors[0] }}
        </b-form-invalid-feedback>
      </b-form-group>
    </validation-provider>
  </b-col>

    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BFormGroup, BFormInvalidFeedback, BFormDatepicker, BFormCheckbox, BFormTextarea, BRow, BCol,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import datePickerLabels from '@/utils/datePickerLabels'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BFormGroup,
    BFormInvalidFeedback,
    BFormDatepicker,
    BFormCheckbox,
    BFormTextarea,
    BRow,
    BCol,

    // Form Validation
    ValidationProvider,
  },
  directives: {
    Ripple,
  },
  props: {
    readOnly: {
      type: Boolean,
      default: false,
    },
    vehiculeData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      checkboxValue: false,
      labels: datePickerLabels,
    }
  },
  watch: {
    checkboxValue() {
      if (!this.checkboxValue) {
        this.vehiculeData.dateEnvoiAtelier = null
        this.vehiculeData.dateRetourAtelier = null
        this.vehiculeData.reserve = null
      }
    },
  },
  mounted() {
    this.checkboxValue = !!(this.vehiculeData.dateEnvoiAtelier || this.vehiculeData.dateRetourAtelier || this.vehiculeData.reserve)
  },
  setup() {
    const { getValidationState } = formValidation()

    return { getValidationState }
  },
}
</script>
